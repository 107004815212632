import { UrlObject } from 'url'
import { FC, SVGProps } from 'react'
import { Divider } from '@/components/elements/Divider'
import { Container } from '@/components/utils'
import { Link } from '@/components/elements/Link'
import { Button } from '@/components/elements/Button'
import { Promoters, PromotersProps } from '@/components/patterns/Promoters'
import {
  Facebook,
  Instagram,
  Telegram,
  Twitter,
  Youtube,
} from '@/components/svg/socialMediaIcons'

export interface FooterLink {
  title: string
  url: string | UrlObject
}

function SocialMediaLink({
  icon,
  url,
  network,
}: {
  url: string
  network: string
  icon: FC<SVGProps<SVGSVGElement>>
}) {
  const Icon = icon

  return (
    <Link
      href={url}
      label={network}
      icon={<Icon className="w-6 h-6" />}
      variant="secondary"
    />
  )
}

export interface FooterProps {
  promoters: PromotersProps
  links: FooterLink[]
  instagramUrl: string
  facebookUrl: string
  twitterUrl: string
  telegramUrl: string
  youtubeUrl: string
  phone: string
  email: string
  address: string
  copyright: string
  signupUrl: string
  signupText: string
}
export function Footer({
  promoters,
  links,
  phone,
  instagramUrl,
  twitterUrl,
  facebookUrl,
  telegramUrl,
  youtubeUrl,
  email,
  address,
  copyright,
  signupUrl,
  signupText,
}: FooterProps) {
  return (
    <footer className="pb-14 text-navy-900 bg-white">
      <Container className="flex flex-col items-center">
        <Promoters {...promoters} />
        <Divider />
        <ul className="flex flex-col flex-wrap items-center justify-center font-semibold md:flex-row">
          {links.map(({ title, url }) => (
            <li key={title} className="px-8 py-2">
              <Link variant="secondary" href={url}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="pt-7">
          <Button variant="outlined" href={signupUrl} label={signupText} />
        </div>
        <Divider />
        <div className="flex gap-6 items-center">
          <SocialMediaLink
            icon={Instagram}
            url={instagramUrl}
            network="Instagram"
          />
          <SocialMediaLink
            icon={Facebook}
            url={facebookUrl}
            network="Facebook"
          />
          <SocialMediaLink icon={Twitter} url={twitterUrl} network="Twitter" />
          <SocialMediaLink
            icon={Telegram}
            url={telegramUrl}
            network="Telegram"
          />
          <SocialMediaLink icon={Youtube} url={youtubeUrl} network="Youtube" />
        </div>
        <div className="flex flex-col flex-wrap items-center justify-center mt-8 text-center text-sm font-semibold sm:flex-row">
          <div className="mx-3 sm:mt-2">
            <Link
              className="py-2 sm:py-0"
              variant="secondary"
              target="_blank"
              href={`tel:${email}`}
            >
              <span>{phone}</span>
            </Link>
          </div>
          <div className="mx-3 sm:mt-2">
            <Link
              className="py-2 sm:py-0"
              variant="secondary"
              target="_blank"
              href={`mailto:${email}`}
            >
              {email}
            </Link>
          </div>
          <div className="mt-2 mx-3">{address}</div>
          <span className="mt-2 mx-3 font-normal">{copyright}</span>
        </div>
      </Container>
    </footer>
  )
}
