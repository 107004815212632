import cn from 'classnames'

interface ContainerProps {
  className?: string
  narrow?: boolean
}

export const Container: React.FC<ContainerProps> = ({
  className = '',
  children,
  narrow = false,
}) => (
  <div
    className={cn(
      `mx-auto px-5 xl:px-0`,
      narrow ? 'max-w-2xl' : 'max-w-7xl',
      className
    )}
  >
    {children}
  </div>
)

interface SectionProps {
  className?: string
}

export const Section: React.FC<SectionProps> = ({
  className = '',
  children,
}) => <div className={cn('py-22', className)}>{children}</div>

interface AutolinkProps {
  className?: string
  text: string
  linkText?: string
}

export const AutoLink: React.FC<AutolinkProps> = ({
  text,
  className = '',
  linkText,
}) => {
  const delimiter =
    // eslint-disable-next-line no-useless-escape
    /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi
  return (
    <>
      {text.split(delimiter).map((word, index) => {
        const match = word.match(delimiter)
        if (match) {
          const url = match[0]
          return (
            <a
              key={index}
              className={className}
              href={url.startsWith('http') ? url : `http://${url}`}
            >
              {linkText || url}
            </a>
          )
        }
        return word
      })}
    </>
  )
}
