import cn from 'classnames'

export interface DividerProps {
  className?: string
  marginless?: boolean
  variant?: 'primary' | 'secondary' | 'light'
  strokeWidth?: number
  strokeDasharray?: string
  height?: number
}

export const Divider: React.FC<DividerProps> = ({
  className = '',
  marginless = false,
  variant = 'primary',
  strokeWidth = 2,
  strokeDasharray = '0.1 12',
  height = '2',
}) => (
  <div
    className={cn(
      `relative w-full pointer-events-none `,
      className,
      {
        'my-10': !marginless,
      },
      {
        'text-navy-900': variant === 'primary',
        'text-orange': variant === 'secondary',
        'text-white': variant === 'light',
      }
    )}
  >
    <svg
      width="100%"
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1"
        x2="100%"
        y2="1"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={strokeDasharray}
      />
    </svg>
  </div>
)
