import React, { useState, useRef } from 'react'
import { motion, AnimatePresence, Variants } from 'framer-motion'
import { CloseOutline, ChevronDown, ChevronUp } from 'react-ionicons'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useOnClickOutside } from '../../../lib/hooks'
import { IonIcon } from '@/src/icons'
import { Link } from '@/components/elements/Link'

const itemVariants: Variants = {
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  enter: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
}

export const ArcordionMenu: React.FC<{
  label: string
  baseUrl: string
  options: MenuOption[]
  onSelected?: (option: MenuOption) => void
}> = ({ label, baseUrl, options, onSelected = () => null }) => {
  const handleSelect = (option: MenuOption) => {
    onSelected(option)
    setSelectedOption(option)
  }
  const [selectedItem, setSelectedItem] = useState(false)

  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
  })
  return (
    <div className="flex flex-col items-center justify-center">
      <button
        type="button"
        onClick={() => setSelectedItem(!selectedItem)}
        className="flex flex-col items-center justify-center w-full"
      >
        <div className="flex">
          <h3
            className={cn(
              'flex-1 text-left text-2xl font-display font-black mb-2 transition-colors',
              {
                'text-navy-900': !selectedItem,
                'text-orange': selectedItem,
              }
            )}
          >
            {label}
          </h3>
          <motion.span
            className="flex items-center justify-center ml-2 h-8"
            initial={{ rotateZ: '-180deg' }}
            animate={selectedItem ? { rotateZ: 0 } : { rotateZ: '-180deg' }}
          >
            <IonIcon
              icon={ChevronUp}
              className={cn('w-6 h-4 text-orange', {
                'text-navy-light-700': !selectedItem,
                'text-orange': selectedItem,
              })}
            />
          </motion.span>
        </div>
      </button>
      <AnimatePresence exitBeforeEnter initial={false}>
        {selectedItem && (
          <motion.p
            variants={itemVariants}
            className="text-lg"
            style={{ transformOrigin: 'top center' }}
            initial="exit"
            animate="enter"
            exit="exit"
          >
            {options.map((option) => (
              <li
                className="group last:mb-0 mb-3 mx-auto w-4/6 hover:text-orange focus:text-orange font-bold cursor-pointer"
                key={option.value}
                role="option"
                aria-selected={selectedOption.value === option.value}
                onClick={() => handleSelect(option)}
                onKeyPress={() => handleSelect(option)}
                tabIndex={0}
              >
                <Link
                  className="group"
                  variant="secondary"
                  href={baseUrl + option.value}
                >
                  <span>
                    {option.label}
                    {option.description && (
                      <p className="group-hover:text-navy-900 text-navy-light-700 text-xs font-normal">
                        {option.description}
                      </p>
                    )}
                  </span>
                </Link>
              </li>
            ))}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  )
}

export interface MenuOption {
  label: string
  value: string
  description?: string
}

export interface MenuDropDownProps {
  id: string
  label: string
  baseUrl?: string
  variant?: 'primary' | 'secondary'
  className?: string
  options: Array<MenuOption>
  onSelected?: (option: MenuOption) => void
}

export const MenuDropDown: React.FC<MenuDropDownProps> = ({
  id,
  label,
  options,
  variant = 'primary',
  className,
  baseUrl,
  onSelected = () => null,
}) => {
  const [expanded, setExpanded] = useState(false)
  const handleSelect = (option: MenuOption) => {
    setExpanded(false)
    onSelected(option)
    setSelectedOption(option)
  }
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
  })

  const ref = useRef(null)
  useOnClickOutside(ref, () => setExpanded(false))

  const router = useRouter()

  const active =
    baseUrl &&
    router &&
    (baseUrl.toString() === router.pathname ||
      baseUrl.toString() === router.asPath ||
      router.pathname.toString().includes(baseUrl.toString()))

  return (
    <div id={id} ref={ref}>
      <button
        type="button"
        className={cn(
          className,
          'relative font-body flex items-center py-4 w-44 font-semibold bg-white justify-center border-opacity-0 focus:text-orange cursor-pointer outline-none transition-all duration-300',
          active
            ? {
                'hover:text-orange': variant === 'primary',
                'hover:text-navy-900': variant === 'secondary',
                'text-navy-900 border-navy-900': variant === 'primary',
                'text-orange border-orange': variant === 'secondary',
              }
            : {
                'hover:text-orange': variant === 'secondary',
                'hover:text-navy-900': variant === 'primary',
                'text-orange border-orange': variant === 'primary',
                'text-navy-900 border-navy-900': variant === 'secondary',
              }
        )}
        onClick={() => setExpanded(!expanded)}
        aria-haspopup="listbox"
        aria-expanded={expanded}
        aria-label={label}
      >
        <div className="flex">
          <span
            role="region"
            aria-live="polite"
            id="selectedOption"
            aria-label={label}
          >
            {label}
          </span>
        </div>
        <span className="flex pl-2 text-navy-900 font-normal" aria-hidden>
          {expanded ? (
            <IonIcon icon={CloseOutline} className="w-5" aria-hidden />
          ) : (
            <IonIcon icon={ChevronDown} className="w-5" aria-hidden />
          )}
        </span>
      </button>
      <AnimatePresence exitBeforeEnter>
        {expanded && (
          <motion.ul
            className="absolute z-10 top-14 mt-2 px-6 py-5 w-72 bg-white rounded-lg shadow-600"
            aria-label={`Options for ${label}`}
            role="listbox"
            initial={{ opacity: 0, y: '10%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: '10%' }}
            aria-controls="selectedOption"
          >
            {options.map((option) => (
              <li
                className="group last:mb-0 mb-3 w-full hover:text-orange focus:text-orange font-bold cursor-pointer"
                key={option.value}
                role="option"
                aria-selected={selectedOption.value === option.value}
                onClick={() => handleSelect(option)}
                onKeyPress={() => handleSelect(option)}
                tabIndex={0}
              >
                <Link
                  className="group"
                  variant="secondary"
                  href={baseUrl + option.value}
                >
                  <span>
                    {option.label}
                    {option.description && (
                      <p className="group-hover:text-navy-900 text-navy-light-700 text-xs font-normal">
                        {option.description}
                      </p>
                    )}
                  </span>
                </Link>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  )
}
